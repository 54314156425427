function scrollPosition(axis) {
	axis = axis || null;

	if (axis === "x")
		return (typeof window.pageXOffset !== "undefined") ? window.pageXOffset : (document.documentElement || document.body.parentNode || document.body).scrollLeft;

	if (axis === "y")
		return (typeof window.pageYOffset !== "undefined") ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

	return 0;
}

export function sticky() {

    let gallery = document.getElementById('main-header-gallery');

    if(gallery) {
        let offset = gallery.offsetHeight;

        if (typeof document.body.dataset === "undefined")
            return;

        if (scrollPosition("y") > offset)
            document.body.classList.add("scrolled");
        else
            document.body.classList.remove("scrolled");
    }
}
