import "../scss/style.scss";
import {initializeCookieConsent} from "./cookie-consent";
import { sliders } from "./sliders";
import { sticky } from "./sticky";

function init() {
    window.addEventListener("scroll", sticky, {passive: true});
}

initializeCookieConsent();
sliders();
init();
