export function initializeCookieConsent()
{
    const buttons = Array.from(document.querySelectorAll("[data-cookie-consent]"));

    if (buttons.length === 0) {
        return;
    }

    buttons.forEach(button => button.addEventListener("click", () => consent(button.dataset.cookieConsent === "1")));
}

function setCookie(key, value, expiresInSeconds)
{
    const d = new Date();
    d.setTime(d.getTime() + (expiresInSeconds * 1000));
    document.cookie = `${key}=${value};expires=${d.toUTCString()};path=/`;
}
function removeNotice(noticeId)
{
    const notice = document.querySelector(noticeId);

    notice
        .animate([
            {transform: "translate3d(0, 0, 0)"},
            {transform: "translate3d(0, 100%, 0)"}
        ], {
            duration: 540,
            easing: "cubic-bezier(.55, 0, .1, 1)"
        })
        .finished
        .then(() => notice.remove());
}
function consent(accept)
{
    setCookie("cookie-consent", accept ? "1" : "0", 365 * 24 * 60 * 60);
    removeNotice("#cookie-consent");
}